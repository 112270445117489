import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserModel } from '../../_models/user.model';
import { environment } from '../../../../../environments/environment';
import { AuthModel } from '../../_models/auth.model';
import { AppHttpClient } from 'src/app/core/services/http-call/http-call.service';

const API_USERS_URL = `${environment.apiUrl}mediator/v1/auth`;

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  constructor(private http: AppHttpClient) { }

  // public methods
  login(mobile: string, password: string): Observable<any> {
    return this.http.post<AuthModel>(`${API_USERS_URL}/login`, { mobile, password });
  }

  // CREATE =>  POST: add a new user to the server
  createUser(user: UserModel): Observable<UserModel> {
    return this.http.post<UserModel>(API_USERS_URL, user);
  }

  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  forgotPassword(email: string): Observable<boolean> {
    return this.http.post<boolean>(`${API_USERS_URL}/forgotPassword`, {
      email,
    });
  }

  updateProfile(profileDetails: UserModel) {
    return this.http.put<boolean>(`${API_USERS_URL}/profile`, profileDetails);
  }

  changePassword(oldPassword: string, newPassword: string) {
    return this.http.post<boolean>(`${API_USERS_URL}/changePassword`, {
      oldPassword,
      newPassword
    });

  }


  resetPassword(token: string, password: string) {
    return this.http.post<boolean>(`${API_USERS_URL}/resetPassword`, {
      token,
      password
    });

  }


  getUserByToken(): Observable<UserModel> {

    return this.http.get<UserModel>(`${API_USERS_URL}/profile`);
  }
}
