export enum Role {
    SUPER_ADMIN = 0,
    ADMIN = 1,
    RESTAURENT_ADMIN = 2
}


export enum WeekDay {
    SUNDAY = 0,
    MONDAY = 1,
    TUESDAY = 2,
    WEDNESDAY = 3,
    THURSDAY = 4,
    FRIDAY = 5,
    SATURDAY = 6
}

export const WeekDaysArray = [{
    value: WeekDay.SUNDAY,
    name: 'Sun'
}, {
    value: WeekDay.MONDAY,
    name: 'Mon'
}, {
    value: WeekDay.TUESDAY,
    name: 'Tue'
}, {
    value: WeekDay.WEDNESDAY,
    name: 'Wed'
}, {
    value: WeekDay.THURSDAY,
    name: 'Thur'
}, {
    value: WeekDay.FRIDAY,
    name: 'Fri'
}, {
    value: WeekDay.SATURDAY,
    name: 'Sat'
}];


export enum DeviceType {
    ANDROID = 1,
    IOS = 2
}

export const DeviceTypeArray = [{
    value: DeviceType.ANDROID,
    name: 'Android'
}, {
    value: DeviceType.IOS,
    name: 'IOS'
}]


export const enum RatingCashbackStatus {
    Pending = 'Pending',
    PickupCompleted = 'Pickup Completed',
    ProductDelivered = 'Product Received',
    Closed = 'Closed',
    Dispute = 'Dispute'
}
